<template>
  <!-- 用户管理 -->
  <div class="alarm">
    <div class="usertop">
      <p>报警管理</p>
    </div>
    <div class="usermain">
      <div class="userbtn">
        <p>报警设备：</p>
        <el-input
          v-model="shebei"
          placeholder="请输入设备名称或编号"
          style="width: 20%; font-size: 18px"
          @keyup.enter.native="chaxun"
        ></el-input>
        <p>报警内容：</p>
        <el-input
          v-model="neirong"
          placeholder="请输入"
          style="width: 20%; font-size: 18px"
          @keyup.enter.native="chaxun"
        ></el-input>
        <el-button
          type="primary"
          style="margin-left: 15px; font-size: 16px"
          @click="chaxun"
          >查询</el-button
        >
      </div>
      <div class="userlist">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column label="报警设备" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.device }}</p>
              <p>{{ scope.row.device_number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="设备运行" show-overflow-tooltip>
            <template slot-scope="scope">
              <p
                @click="toview(scope.$index, scope.row)"
                style="color: #5c98f7; cursor: pointer"
              >
                查看详情
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="content_error"
            label="报警内容"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="报警时间"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="userpagging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage1"
            :page-sizes="[10, 15, 20]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorlist } from "../../api/alarm/list.js";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      shebei: "",
      neirong: "",
      value: "",
      tableData: [],
      // 这个是控制选择框的参数
      currentPage1: 1,
      total: 0,
      // 搜索后的
      sousuo: "",
      content: "",
      //
      pagesize: 10,
    };
  },
  created() {
    errorlist("", "", "", 1, 10).then((res) => {
      console.log(res.data);
      let str = res.data;
      if (str) {
        this.total = str.total;
        this.tableData = str.data;
      }
    });
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.carelist(this.shebei, "", this.neirong, 1, this.pagesize);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.carelist(
        this.shebei,
        "",
        this.neirong,
        this.currentPage1,
        this.pagesize
      );
    },
    // 查询
    chaxun() {
      this.currentPage1 = 1;
      this.carelist(this.shebei, "", this.neirong, 1, this.pagesize);
      this.sousuo = JSON.parse(JSON.stringify(this.shebei));
      this.content = JSON.parse(JSON.stringify(this.neirong));
    },
    // 查看详情
    toview(index, row) {
      console.log(index);
      console.log(row);
      console.log("查看详情");
      this.$router.push("/userdetail");
    },
    // 查看报警设备
    carelist(sousuo, qiye, baocuo, page, pagesize) {
      errorlist(sousuo, qiye, baocuo, page, pagesize).then((res) => {
        let str = res.data;
        if (str) {
          this.total = str.total;
          this.tableData = str.data;
        }
      });
    },
  },
};
</script>

<style  lang='less' scoped>
.alarm {
  padding: 20px;
  .usertop {
    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .usermain {
    min-height: 760px;
    background: white;
    padding: 35px;
    padding-bottom: 15px;
    .userbtn {
      display: flex;
      font-size: 22px;
      align-items: center;
      /deep/.el-button--success {
        font-size: 20px;
      }
      /deep/.el-button--danger {
        font-size: 20px;
      }
      /deep/.el-input__inner {
        font-size: 18px;
      }
      /deep/.el-select-dropdown__item {
        font-size: 20px;
      }
      /deep/.el-button--primary {
        font-size: 20px;
      }
      /deep/.el-input {
        margin-right: 20px;
      }
    }
    .userlist {
      .selbtn {
        display: flex;
        p {
          margin-right: 10px;
          color: #5c89f4;
          cursor: pointer;
        }
      }
    }
    .userpagging {
      margin-top: 20px;
    }
  }
  /deep/.el-table th,
  .el-table tr {
    font-size: 20px !important;
    height: 70px;
    background: #f2f2f2;
  }
  /deep/.el-table__body,
  .el-table__footer,
  .el-table__header {
    font-size: 20px !important;
  }
  /deep/.el-switch__label * {
    font-size: 16px;
  }
  /deep/.el-pagination__total {
    font-size: 18px !important;
  }
  /deep/.el-pager li {
    font-size: 17px;
  }
  /deep/.el-pagination__jump {
    font-size: 20px !important;
  }
  /deep/.el-input__inner {
    font-size: 17px;
  }
  /deep/.el-input--suffix .el-input__inner {
    font-size: 18px !important;
  }
  /deep/.el-dialog__title {
    font-size: 24px;
  }
  /deep/.el-dialog__body {
    font-size: 20px !important;
  }
}
</style>
